.tool-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #333;
  color: white;
  justify-content: center;
  margin: 0 auto;
  min-height: 100vh; /* Ensure it takes up the full viewport height */
}


.tool-page-content {
  max-width: 400px;
  width: 100%;
  background-color: #333;
  padding: 20px;
  padding-top: 0px;
}

/* Add margin-top for mobile devices */
@media (max-width: 768px) {
  .tool-page-content {
    margin-top: 0; /* Remove extra margin for mobile devices */
    padding-bottom: 20px; /* Add padding at the bottom */
  }
}
 
  .external-link {
    display: inline-block;
    padding: 5px 10px; /* Adjust padding to make it smaller */
    background-color: white;
    color: black;
    text-decoration: none;
    border: 1px solid black;
    border-radius: 4px;
    font-family: 'Inter';
    font-size: 12px; /* Adjust font size to make it smaller */
  }
  
  .external-link:hover {
    background-color: black;
    color: white;
  }
  
  
  .external-arrow {
    margin-left: 5px;
    
  }
  
  .alternative-boilerplates {
    margin-top: 30px;
    width: 100%;
  }
  
  .alternative-boilerplates h3 {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
  
  }
  
  .alternative-boilerplates .tool-card {
    margin-bottom: 20px;
    max-width: 25em;
    height: 5.5em;

  }

  .alternative-boilerplates .tool-details {
    margin-top: 20px;

  }

  .alternative-boilerplates .tool-description {
    font-size: 14px;
    height: 3em;
  }


  .alternative-boilerplates .tool-description-55 {
    font-size: 10px;
    
  }

  .tool-description-5 {
    max-width: 30em ;
  }
  
  .alternative-boilerplates .free-pill {
    margin-bottom: 26px;
    max-width: 20em;
    margin-left: 9em;
   
  }


  .tool-icon {
    width: 80px; /* Adjust the size as needed */
    height: auto;
    border-radius: 8px;
    margin-bottom: 0px; /* Optional: Adds some spacing */
  }










  
  .main-bg {
    background-color: #333;
    margin: 0 auto;
    min-height: 100vh; /* Ensure it takes up the full viewport height */
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  
  .main-content-2 {
    width: 100%; /* Ensure full width for the container */
    max-width: 970px;
    margin: 0 auto;
  }


  /* ToolPage.css or your CSS file */
.tag-icon {
  width: 25px; /* Adjust size as needed */
  height: 25px; /* Adjust size as needed */
  margin-right: 8px; /* Optional: Add spacing between icons */
  margin-bottom: 1em;
}

.tool-name-5 {
  margin-bottom: 0px;
}


/* Add margin-top for mobile devices */
@media (max-width: 768px) {
  .tool-page-content {
    margin-top: 2em; /* Add margin-top for mobile devices */
  }
}

@media (max-width: 768px) {
  .main-bg {
    padding-bottom: 0; /* Adjust as needed */
  }
}