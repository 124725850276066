/* Common styles for ranked-tools-list */
.ranked-tools-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%; /* Adjust width to 100% to fit the container */
  max-width: 700px; /* Set max width for larger screens */
  
}

.ranked-tool-card {
  background-color: #444;
  padding: 5px;
  border-radius: 12px; /* Adjust border radius */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex; /* Ensure card is displayed as flex container */
  position: relative; /* Needed for absolute positioning inside */
  height: auto; /* Auto height for flexibility */
  flex-direction: column; /* Stack content vertically for smaller screens */
}

.medal {
  font-size: 1.5em;
  position: absolute;
  top: 10px; /* Adjust position from top */
  left: 10px; /* Adjust position from left */
}

.tool-details-2 {
  flex: 1; /* Allow tool details to take remaining space */
  display: flex;
  flex-direction: column; /* Stack content vertically */
  align-items: center; /* Center align for smaller screens */
  text-align: center; /* Center text for smaller screens */
}

.screenshot-container {
  width: 100%; /* Adjust width to fit container */
  height: auto; /* Adjust height to auto */
  overflow: hidden;
  border-radius: 12px; /* Adjust border radius */
  margin-bottom: 15px; /* Adjust margin as needed */
}

.image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.screenshot {
  width: 100%; /* Ensure image fills the container horizontally */
  height: auto; /* Adjust height to auto */
  object-fit: cover; /* Scale the image to be as large as possible while ensuring its aspect ratio is maintained and it covers the entire container */
  border-radius: 12px; /* Ensure border radius matches parent */
}

.tool-info-2 {
  flex: 1; /* Allow tool info to take remaining space */
  width: 100%;
}

.tool-title-2 {
  margin-top: 0;
  font-size: 1.2em;
}

.tool-description-2 {
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0;
}

.icon-row {
  display: flex;
  gap: 5px;
  justify-content: center; /* Center align icons for smaller screens */
  margin-top: 5px;
}

.icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

/* Media query for smaller screens */
@media (min-width: 600px) {
  .ranked-tool-card {
    flex-direction: row; /* Row direction for larger screens */
    align-items: center; /* Align items center for larger screens */
    text-align: left; /* Left align text for larger screens */
  }

  .screenshot-container {
    width: 300px; /* Fixed width for larger screens */
    height: 160px; /* Fixed height for larger screens */
    margin-right: 15px; /* Adjust margin as needed */
    margin-bottom: 0; /* Remove bottom margin for larger screens */
  }

  .tool-details-2 {
    flex-direction: row; /* Row direction for larger screens */
    text-align: left; /* Left align text for larger screens */
  }

  .tool-info-2 {
    padding-left: 15px; /* Add padding for larger screens */
  }

  .icon-row {
    justify-content: flex-start; /* Left align icons for larger screens */
  }
}








.visit-website-button {
  background-color: orange;
  color: black;
  border: none;
  padding: 3px 10px;
  cursor: pointer;
  font-size: 11px;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
  font-family: 'Inter';
  margin-top: 1.5em;
}

.visit-website-button:hover {
  background-color: darkorange;
}



.rank-number {
  position: absolute;
  top: 25px;
  left: -35px;
  font-size: 1.1em;
  color: white;
}


/* Hide rank numbers on smaller screens */
@media (max-width: 600px) {
  .rank-number {
    display: none;
  }
}