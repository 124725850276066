.submit-page {
    display: flex;
    justify-content: center;
   
    min-height: 150vh;
    background-color: #333;
    color: white;
    
  }
  
  .centered-content {
    text-align: center;
    top: 10em;
    position: relative;
  }
  
  .subhead {
    font-weight: 600;
    font-size: 20px;
    color: orange;
  }

  .logo {
    width: 100px; /* Adjust size as needed */
    margin-bottom: 10px;
  }
  
  .checklist2 {
    background-color: #333;
    padding: 20px;
    border-radius: 8px;
    text-align: left;
  }
  
  .checklist-item {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .checkmark {
    color: green;
    margin-right: 10px;
    font-size: 20px;
  }

  .headersoon {
    display: flex;
    align-items: center;
    padding-left: 25px;
  }


  .home-button {
    display: inline-block;
    margin-top: 20px;
    padding: 5px 12px;
    background-color: orange;
    color: #333;
    text-decoration: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    font-family: 'Inter';
    margin-top: 3em;
  }
  
  .home-button:hover {
    background-color: #f90;
  }

  /* Center the button */
.home-button-container {
    text-align: center;
  }
  

  .ofday {
    font-weight: 600;
    border-bottom: 2px solid orange;
  }

  .adh1 {
    display: flex;
    justify-content: center;
  }