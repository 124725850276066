/* src/components/Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  background-color: #333;
  color: #eee;
  
}

.logo {
  font-size: 1.2em;
  font-weight: bold;
  
 
}

.app-name {
  flex: 1;
  text-align: left;
  margin-left: 0px; /* Adjust margin as needed */
  font-size: 1.2em;
  font-weight: bold;
}

.submit-button {
  padding: 5px 10px;
  font-size: 1em;
  text-decoration: none;
  color: inherit; /* Use the default text color */
}

.submit {
  padding: 5px 10px;
  font-size: 14px;
  color: #bbb;
  text-decoration: none !important;
  margin-left: 20px; /* Adjust margin as needed */
}

.best-page {
  padding: 5px 10px;
  font-size: 14px;
  color: #bbb;
  text-decoration: none !important;
  margin-right: 20px; /* Adjust margin as needed */
}

.title-color {
  color: #FFA500;
}

@keyframes buzz {
  0% { transform: translate(0, 0); }
  10% { transform: translate(-1px, -1px); }
  20% { transform: translate(1px, 1px); }
  30% { transform: translate(-1px, 1px); }
  40% { transform: translate(1px, -1px); }
  50% { transform: translate(-1px, -1px); }
  60% { transform: translate(1px, 1px); }
  70% { transform: translate(-1px, 1px); }
  80% { transform: translate(1px, -1px); }
  90% { transform: translate(-1px, -1px); }
  100% { transform: translate(0, 0); }
}

.logo {
  display: inline-block; /* Ensure inline-block for proper animation */
  transition: transform 0.2s ease-in-out; /* Smooth transition on hover effects */

}

.logo:hover {
  animation: buzz 0.5s linear; /* Animation duration and linear easing */
  animation-iteration-count: infinite; /* Repeat the animation infinitely on hover */
}

.navhead {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit; /* Use the default text color */
 
}

/* Dropdown styles */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background: none;
  border: none;
  color: #bbb;
  font-size: 14px;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-button:focus {
  outline: none;
}

.dropdown-arrow {
  margin-left: 5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #bbb;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #444;
  min-width: 200px; /* Adjust width as needed */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  font-size: 14px;
  border-radius: 8px;
}

.dropdown-item {
  color: #bbb;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  white-space: nowrap; /* Ensure content stays on a single line */
}

.dropdown-item:hover {
  background-color: #555;
  border-radius: 8px;
}


.colored-text-orange {
  color: orange;
}

