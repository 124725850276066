.sidebar {
  width: 180px;
  background-color: #333;
  color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid rgb(96, 96, 96);
}

.emoji-box {
  width: 20px;
  height: 20px;
  border: 1px solid #666;
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;
}

.category-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
  width: 100%;
}

.category-item:hover {
  background-color: #555;
  border-radius: 5px;
}

.category-name {
  margin-left: 8px;
  font-size: 14px;
  color: #eee;
}

.category-item.selected .category-name {
  color: orange; /* Text color becomes orange for selected category */
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    height: auto;
    padding: 10px;
  }

  .emoji-box {
    font-size: 14px;
  }

  .category-name {
    font-size: 14px;
  }
}
