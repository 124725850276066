/* src/components/CardList.css */
.card-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Display 4 columns with equal width */
  gap: 20px; /* Adjust the gap between grid items as needed */
  margin-bottom: 10em;
}

@media (max-width: 768px) {
  .card-list {
    grid-template-columns: 1fr; /* Display one column on smaller screens */
  }
}

.card {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 20px;
  width: 100%; /* Adjust width to be 100% of the grid column */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}








.search-bar {
  display: flex;
  align-items: center;
  margin-top: 20px; /* Space between buttons and search bar */
  margin-bottom: 20px;
}

.search-input {
  flex: 1;
  padding: 15px;
  border: none;
  border-radius: 20px; /* Rounded edges */
  background-color: #f7f6f6;
  outline: none; /* Remove focus outline */
  font-family: 'Inter', sans-serif; /* Apply Inter font to main layout */

}

.search-icon {
  margin-left: 10px; /* Space between input field and search icon */
  font-size: 1.2em;
  color: #666;
}
