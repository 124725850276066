/* src/components/AppLayout.css */
.app-layout {
  display: flex;
  min-height: 100vh; /* Make the layout take up the full viewport height */
  font-family: 'Inter', sans-serif; /* Apply Inter font to main layout */
  background-color: #333;
  color: #eee;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 1200px; /* Set maximum width for the main content */
  margin: 0 auto; /* Center align the main content */
  background-color: #333;
}

.top-container {
  display: flex;
}

.content {
  padding: 20px;
  flex: 1;
  background-color: #333;
  padding-top: 60px;
  margin-top: 0px;
}

.ad-cta {
  display: flex;
  font-size: 12px;
  color: rgb(104, 103, 103);
  margin-bottom: .5em;
  padding-right: 5px;
  align-items: center;
  text-align: right;
}


/* Responsive Design */
@media (max-width: 768px) {
  .app-layout {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }

 

  .sidebar {
    display: none; /* Hide sidebar on smaller screens */
  }

  .main-content {
    margin-top: 20px;
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .card-list {
    grid-template-columns: 1fr; /* Display one column */
  }

  .top-container {
    flex-direction: column; /* Ensure top container items stack vertically */
  }

  .ad-cta {
    margin-top: 10px; /* Add space between the subheader and ad-cta on mobile */
    padding-left: 0; /* Align the ad-cta to the left */
    
  }

  .submit-button {
    margin-left: 0;
    margin-top: 5em;
    margin-bottom: 1em;
  }


  .content {
    padding-top: 0px;
  }
}

/* Additional CSS */
.app-layout {
  font-family: 'Inter', sans-serif; /* Apply Inter font to main layout */
}

.header {
  margin-bottom: '40px';
}

.h2 {
  color: #eee;
}

.jumbo {
  font-size: 30px;
}

#highlighted-text {
  color: orange;
}

.button-container {
  display: flex;
  gap: 10px; /* Adjust gap between buttons */
  margin-top: 20px;
  margin-bottom: 20px;
}

.submit-button, .subscribe-button {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: none;
  border-radius: 20px; /* Rounded edges */
  font-size: 0.9em;
  cursor: pointer;
}

.submit-button {
  background-color: #222;
  color: white;
  font-family: 'Inter', sans-serif; /* Apply Inter font to main layout */
  font-size: 14px;
}

.subscribe-button {
  background-color: #f7f6f6;
  color: black;
  font-family: 'Inter', sans-serif; /* Apply Inter font to main layout */
  font-size: 14px;
}

.button-icon {
  margin-right: 0px; /* Space between icon and text */
}

.search-input::placeholder {
  color: #bbb; /* Adjust the color to your preference */
}

.search-bar {
  display: flex;
  align-items: center;
  margin-top: 0px; /* Space between buttons and search bar */
  margin-bottom: 20px;
}

.search-input {
  flex: 1;
  padding: 15px;
  border: none;
  border-radius: 20px; /* Rounded edges */
  background-color: #444;
  outline: none; /* Remove focus outline */
  font-family: 'Inter', sans-serif; /* Apply Inter font to main layout */
  color: #bbb; /* Adjust the color to your preference */
}

.search-icon {
  margin-left: 10px; /* Space between input field and search icon */
  font-size: 1.2em;
  color: #666;
}

.checklist {
  list-style-type: none;
  padding: 0;
  max-width: 700px;
  font-size: 15px;
  color: #bcbaba;
  height: 5em;
}

.checkmark {
  margin-right: 10px;
}

#letter-icon {
  width: 18px;
}

.card-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Display 4 columns with equal width */
  gap: 20px; /* Adjust the gap between grid items as needed */
}

.tool-card {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#letter-icon {
  margin-right: 3px;
}

.tool-details-right {
  position: relative;
  left: 0em;
  padding: 10px;
  padding-left: 0px;
  align-items: center;
  display: grid;
  bottom: 6px;
}

.logo-container-right {
  width: 80px; /* Adjust size of logo container as needed */
  height: 80px;
  margin-right: 0px;
}

.right-side {
  align-items: right;
  display: grid;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: 1.3em;
}

.tool-logo-2 {
  width: 50%;
  height: 50%;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  border-radius: 12px;
  position: relative;
  top: 20px;
  left: 10px;
}

.tool-name-2 {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #989797;
}

.tool-description-2 {
  margin-bottom: 0px;
  font-size: 12px;
  position: relative;
  bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #989797;
}

.tool-card-2 {
  display: flex;
  align-items: center;
  background-color: #444 !important;
  padding: 6px !important;
  border-radius: 8px;
  max-width: 16.5em;
  max-height: 4.5em;
  flex: 1;
  box-shadow: none !important;
}

.tool-description-3 {
  margin-bottom: 0px;
  font-size: 12px;
  position: relative;
  bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #989797;
}

.ad-cta {
  display: flex;
  font-size: 12px;
  justify-content: right;
  color: rgb(104, 103, 103);
  margin-bottom: .5em;
  padding-right: 5px;
  align-items: center;

}

#letter-icon {
  width: 18px;
}

@media (max-width: 768px) {
  .app-layout {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }

  .sidebar {
    display: none; /* Hide sidebar on smaller screens */
  }

  .main-content {
    margin-top: 0px;
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .card-list {
    grid-template-columns: 1fr; /* Display one column */
  }

  .top-container {
    flex-direction: column; /* Ensure top container items stack vertically */
  }

  .ad-cta {
    margin-top: 10px; /* Add space between the subheader and ad-cta on mobile */
    padding-left: 0; /* Align the ad-cta to the left */
    text-align: right; /* Align text to the left */
  }

  .right-side {
    
    margin: 0 auto;
    margin-bottom: 2em;
  
  }
}

.sidebar.open {
  display: block; /* Show sidebar when open */
  position: absolute;
  width: 100%;
  background-color: #333;
  z-index: 1000;
  height: 100vh;
  left: 0;
  top: 0;
}

.tool-card {
  background-color: #444 !important;
}

.submit-button,
.subscribe-button {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: none;
  border-radius: 20px; /* Rounded edges */
  font-size: 0.9em;
  cursor: pointer;
}

.submit-button {
  background-color: #222;
  color: white;
  font-family: 'Inter', sans-serif; /* Apply Inter font to main layout */
  font-size: 14px;
}

.subscribe-button {
  background-color: #f7f6f6;
  color: black;
  font-family: 'Inter', sans-serif; /* Apply Inter font to main layout */
  font-size: 14px;
}

.checklist {
  list-style-type: none;
  padding: 0;
  max-width: 700px;
  font-size: 15px;
  color: #bcbaba;
}

.checkmark {
  margin-right: 10px;
}

#letter-icon {
  width: 18px;
}

.tool-card {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

