.tool-card {
  display: flex;
  align-items: center;
  background-color: #444 !important;
  padding: 6px !important;
  border-radius: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  flex: 1;
}

.tool-card:hover {
  box-shadow: 0px 1px 3px black;
}

.logo-container {
  width: 80px; /* Adjust size of logo container as needed */
  height: 80px;
  margin-right: 5px;
}

.tool-logo {
  width: 80%;
  height: 80%;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  border-radius: 12px;
  position: relative;
  top: 8px;
}

.tool-details {
  flex: 1;
  position: relative;
  bottom: 10px;
}

.tool-name {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tool-description {
  margin-bottom: 0px;
  font-size: 12px;
  position: relative;
  bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #b1b1b1;
}

.free-pill {
  position: absolute;
  bottom: 5.6em;
  left: 12em;
  background-color: orange; /* Green color */
  color: #333;
  padding: 3px 8px;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 600;
}

.tool-card-link {
  text-decoration: none;
  color: inherit; /* Use the default color for links */
}

.saas-of-the-day-pill {
  position: absolute;
  top: 80px;
  right: 55px;
  background-color: orange;
  color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 11px;
  font-weight: 600;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any overflowing text */
  text-overflow: ellipsis; /* Display an ellipsis (...) to indicate text overflow */
}

/* Responsive Design */
@media (max-width: 768px) {
  .saas-of-the-day-pill {
    top: 80px; /* Adjust as needed */
    right: 160px;
  }
}

.saas-of-the-day {
  border: 2px solid orange !important; /* Ensure this line is included for the orange border */
}

.saas-of-the-day-border {
  border-color: orange; /* Ensure this line specifies the border color */
}

.tag-icon {
  width: 16px; /* Adjust the width and height to your preferred size */
  height: 16px;
  margin-right: 8px; /* Optional: Add margin between icons */
  color: white;
}

.dark-icon {
  filter: greyscale(0%) brightness(50%);
}

.icon-row {
  display: flex; /* Ensure icons are displayed in a row */
  flex-wrap: nowrap; /* Prevent wrapping to multiple rows */
}
